import Vue from 'vue';

// bootstrap
import { BootstrapVue, BIcon, BIconCircleFill } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';

Vue.use(BootstrapVue);
Vue.component('BIcon', BIcon);
Vue.component('BIconCircleFill', BIconCircleFill);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
