



































































import Axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  private query = '';

  private loading = false;

  private items: any = {
    query: '',
    data: [],
  };

  private fields: any[] = [
    { key: 'cvid', label: 'CVID', class: 'text-center' },
    { key: 'url', label: 'SmartLabel', class: 'text-center' },
  ];

  private async onSearch() {
    if (!this.query) {
      return;
    }

    this.loading = true;
    this.updateUrl();

    try {
      const res1 = await Axios.get('https://mcui85ov9j.execute-api.us-east-1.amazonaws.com/sldevtools/f2f9a770-5d79-4c8d-913a-9cd888d4f52b', {
        params: {
          gtin: this.query,
        },
      });

      const res2 = await Axios.get('https://mcui85ov9j.execute-api.us-east-1.amazonaws.com/scanbuy', {
        params: {
          gtin: this.query,
        },
      });

      this.items.query = this.query;
      this.items.data = [...res1.data, ...res2.data];
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  private reset() {
    this.query = '';
    this.items.query = '';
    this.items.data = [];

    const q: any = document.getElementById('query');
    q.focus();
  }

  private updateUrl() {
    window.history.pushState({}, '', `${window.location.origin}?upc=${this.query}`);
  }

  private mounted() {
    const urlParams: any = new URLSearchParams(window.location.search);

    if (urlParams.has('upc')) {
      this.query = urlParams.get('upc').toString();
      this.onSearch();
    }
  }
}
